import React, { useContext, useEffect, useState } from "react";
import { Login } from "../login";
import ApplicationContext from "../../context/ApplicationContext";
import { ViewPage } from "../../types/ViewPage";
import { IManagerProps } from "./IManagerProps";

import { getSettings } from "../../services/Service";
import loadingImage from "../../images/loading.gif";
import { IInstruction } from "../../types/IInstruction";

import { ITableData } from "../../types/ITableData";

import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { AllProducts } from "../pages/products/AllProducts";
import { AddProduct } from "../pages/products/AddProduct";
import { AddExtra, Extras } from "../pages/extras";
import { AddInstructions, Instructions } from "../pages/Instructions";
import { AddVariation, Variations } from "../pages/variation";
import { AddOption, Options } from "../pages/Options";
import { MultiInstructions } from "../pages/Instructions/MultiInstructions";
import { ProductTree } from "../pages/productTree/ProductTree";
import { CopyInstructions } from "../pages/Instructions/CopyInstructions";

import { SelectInstructions } from "../pages/Instructions/SelectInstructions";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { Calls } from "../pages/Calls/Calls";

import cn from "classnames";

import "./css/app.css";

export const Manager: React.FC<IManagerProps> = (
  props: IManagerProps
): JSX.Element => {
  const defaultInstructions: IInstruction[] = [];

  const context = useContext(ApplicationContext);
  const [loginView, setLoginView] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);

  const [newProduct, setNewProduct] = React.useState(false);
  const [viewProducts, setViewProducts] = React.useState(false);
  const [viewExtras, setViewExtras] = React.useState(false);
  const [viewAddExtras, setViewAddExtras] = React.useState(false);
  const [viewVariations, setViewVariations] = React.useState(false);
  const [viewAddVariation, setViewAddVariation] = React.useState(false);

  const [viewInstructions, setViewInstructions] = React.useState(false);
  const [viewAddInstructions, setViewAddInstructions] = React.useState(false);

  const [viewOptions, setViewOptions] = React.useState(false);
  const [viewAddOptions, setViewAddOptions] = React.useState(false);

  const [showSideBar, setShowSideBar] = React.useState(true);
  const [showMenu, setShowMenu] = React.useState(false);

  const navigate = useNavigate();
  const defaultTableData: ITableData[] = [];

  const handleLoginSuccess = () => {
    setLoginView(false);
    context.ViewPage.setView(ViewPage.home);
    setViewProducts(true);
  };

  const handleRegister = () => {};
  const handleForgotPassword = () => {};

  function toggleRootClass() {
    const current = document.documentElement.getAttribute("data-bs-theme");
    const inverted = current == "dark" ? "light" : "dark";
    document.documentElement.setAttribute("data-bs-theme", inverted);
  }

  function toggleLocalStorage() {
    if (isLight()) {
      localStorage.removeItem("light");
    } else {
      localStorage.setItem("light", "set");
    }
  }

  function isLight() {
    return localStorage.getItem("dark");
  }

  if (isLight()) {
    toggleRootClass();
  }

  async function getBranchSettings() {
    setLoading(true);

    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    context.BranchMenu.setMenu(response.menu);
    context.Configuration.setSettings(response.branchSettings);

    //setProducts(context.BranchMenu.getMenu().products);

    setLoading(false);
  }

  function toggleMenu() {}

  useEffect(() => {
    setLoadingError(true);
  }, [context.ApplicationError.hasError()]);

  useEffect(() => {
    //getBranchSettings();
  }, []);

  useEffect(() => {
    //getBranchSettings();
  }, [context.Configuration.invalidate()]);

  function showProducts() {
    navigate("/Products");
  }

  function showDashboard() {
    navigate("/Dashboard");
  }

  function showAddProducts() {
    navigate("/AddProduct");
  }

  function showAllExtras() {
    navigate("/Extras");
  }

  function showAddExtras() {
    navigate("/AddExtra");
  }

  function showVariations() {
    navigate("/Variations");
  }
  function showAddVariations() {
    navigate("/AddVariation");
  }

  function showAddInstructions() {
    navigate("/AddInstruction");
  }

  function showInstructions() {
    navigate("/Instructions");
  }

  function showOptions() {
    navigate("/Options");
  }

  function showAddOptions() {
    navigate("/AddOption");
  }

  function showMultiInstructions() {
    navigate("/MultiInstruction");
  }

  function showProductTreeView() {
    navigate("/TreeView");
  }

  function showCopyInstructions() {
    navigate("/CopyInstructions");
  }

  function toggleSideBar() {
    setShowMenu(!showMenu);
  }

  const showLeftMenu = cn("sidebar", "js-sidebar", { collapsed: showMenu });

  const template = (): JSX.Element => (
    <>
      <div className="wrapper">
        <nav id="sidebar" className={showLeftMenu}>
          <div className="sidebar-content js-simplebar">
            <a className="sidebar-brand">
              <span className="align-middle">WaEPOS Admin</span>
            </a>
            <ul className="sidebar-nav">
              <div style={{ height: "90vh", overflow: "auto" }}>
                <li className="sidebar-item">
                  <a className="sidebar-link">
                    <i className="align-middle" data-feather="sliders"></i>{" "}
                    <div onClick={() => showDashboard()}>
                      <span className="align-middle">Dashboard</span>
                    </div>
                  </a>
                </li>
                <li className="sidebar-header">Menu Setup</li>
                <li className="sidebar-item">
                  <a className="sidebar-link">
                    <i className="align-middle" data-feather="square"></i>

                    <span
                      className="align-middle"
                      onClick={() => showProductTreeView()}
                    >
                      App
                    </span>
                  </a>
                </li>
                <li className="sidebar-item">
                  <a className="sidebar-link">
                    <i className="align-middle" data-feather="check-square"></i>{" "}
                    <span
                      className="align-middle"
                      onClick={() => showDashboard()}
                    >
                      Mobile
                    </span>
                  </a>
                </li>
                <li className="sidebar-header">Configuration</li>
                <li className="sidebar-item">
                  <a className="sidebar-link" href="charts-chartjs.html">
                    <i className="align-middle" data-feather="bar-chart-2"></i>{" "}
                    <span className="align-middle">Defaults</span>
                  </a>
                </li>
                <li className="sidebar-item">
                  <a className="sidebar-link" href="maps-google.html">
                    <i className="align-middle" data-feather="map"></i>{" "}
                    <span className="align-middle">User</span>
                  </a>
                </li>
                <li className="sidebar-header">Helpers</li>
                <li className="sidebar-link">
                  <div
                    style={{ paddingLeft: 15 }}
                    onClick={() => showProducts()}
                  >
                    <a>Products</a>
                  </div>

                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showProducts()}
                  >
                    <a>All</a>
                  </li>
                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showAddProducts()}
                  >
                    <a>Add</a>
                  </li>
                </li>
                <li className="sidebar-link">
                  <div
                    style={{ paddingLeft: 15 }}
                    onClick={() => showAllExtras()}
                  >
                    <a>Extra</a>
                  </div>

                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showAllExtras()}
                  >
                    <a>All</a>
                  </li>
                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showAddExtras()}
                  >
                    <a>Add</a>
                  </li>
                </li>
                <li className="sidebar-link">
                  <div
                    style={{ paddingLeft: 15 }}
                    onClick={() => showVariations()}
                  >
                    <a>Variations</a>
                  </div>

                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showVariations()}
                  >
                    <a>All</a>
                  </li>
                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showAddVariations()}
                  >
                    <a>Add</a>
                  </li>
                </li>
                <li className="sidebar-link">
                  <div
                    style={{ paddingLeft: 15 }}
                    onClick={() => showInstructions()}
                  >
                    <a>Instructions</a>
                  </div>

                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showInstructions()}
                  >
                    <a>All</a>
                  </li>
                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showAddInstructions()}
                  >
                    <a>Add</a>
                  </li>
                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showCopyInstructions()}
                  >
                    <a>Copy</a>
                  </li>
                </li>
                <li className="sidebar-link">
                  <div style={{ paddingLeft: 15 }} onClick={showOptions}>
                    <a>Options</a>
                  </div>

                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showOptions()}
                  >
                    <a>All</a>
                  </li>
                  <li
                    className="sidebar-link"
                    style={{ paddingLeft: 25 }}
                    onClick={() => showAddOptions()}
                  >
                    <a>Add</a>
                  </li>
                </li>
                <li className="sidebar-link">
                  <div
                    style={{ paddingLeft: 15 }}
                    onClick={() => showProducts()}
                  ></div>
                </li>
                <li className="sidebar-item">
                  <a className="sidebar-link" href="charts-chartjs.html">
                    <i className="align-middle" data-feather="bar-chart-2"></i>{" "}
                    <span className="align-middle">Defaults</span>
                  </a>
                </li>
                <li className="sidebar-item">
                  <a className="sidebar-link" href="maps-google.html">
                    <i className="align-middle" data-feather="map"></i>{" "}
                    <span className="align-middle">User</span>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </nav>

        <div className="main" style={{ height: "100vh" }}>
          <nav className="navbar navbar-expand navbar-light navbar-bg">
            <a className="sidebar-toggle js-sidebar-toggle">
              <i
                className="hamburger align-self-center"
                onClick={() => toggleSideBar()}
              ></i>
            </a>

            <div className="navbar-collapse collapse">
              <ul className="navbar-nav navbar-align">
                <li className="nav-item dropdown">
                  <a
                    className="nav-icon dropdown-toggle"
                    href="#"
                    id="messagesDropdown"
                    data-bs-toggle="dropdown"
                  >
                    <div className="position-relative">
                      <i
                        className="align-middle"
                        data-feather="message-square"
                      ></i>
                    </div>
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
                    aria-labelledby="messagesDropdown"
                  >
                    <div className="dropdown-menu-header">
                      <div className="position-relative">4 New Messages</div>
                    </div>
                    <div className="list-group">
                      <a href="#" className="list-group-item">
                        <div className="row g-0 align-items-center">
                          <div className="col-2">
                            {/* <img src="img/avatars/avatar-5.jpg" className="avatar img-fluid rounded-circle" alt="Vanessa Tucker"> */}
                          </div>
                          <div className="col-10 ps-2">
                            <div className="text-dark">Vanessa Tucker</div>
                            <div className="text-muted small mt-1">
                              Nam pretium turpis et arcu. Duis arcu tortor.
                            </div>
                            <div className="text-muted small mt-1">15m ago</div>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="list-group-item">
                        <div className="row g-0 align-items-center">
                          <div className="col-2">
                            {/* <img src="img/avatars/avatar-2.jpg" className="avatar img-fluid rounded-circle" alt="William Harris"> */}
                          </div>
                          <div className="col-10 ps-2">
                            <div className="text-dark">William Harris</div>
                            <div className="text-muted small mt-1">
                              Curabitur ligula sapien euismod vitae.
                            </div>
                            <div className="text-muted small mt-1">2h ago</div>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="list-group-item">
                        <div className="row g-0 align-items-center">
                          <div className="col-2">
                            {/* <img src="img/avatars/avatar-4.jpg" className="avatar img-fluid rounded-circle" alt="Christina Mason"> */}
                          </div>
                          <div className="col-10 ps-2">
                            <div className="text-dark">Christina Mason</div>
                            <div className="text-muted small mt-1">
                              Pellentesque auctor neque nec urna.
                            </div>
                            <div className="text-muted small mt-1">4h ago</div>
                          </div>
                        </div>
                      </a>
                      <a href="#" className="list-group-item">
                        <div className="row g-0 align-items-center">
                          <div className="col-2">
                            {/* <img src="img/avatars/avatar-3.jpg" className="avatar img-fluid rounded-circle" alt="Sharon Lessman"> */}
                          </div>
                          <div className="col-10 ps-2">
                            <div className="text-dark">Sharon Lessman</div>
                            <div className="text-muted small mt-1">
                              Aenean tellus metus, bibendum sed, posuere ac,
                              mattis non.
                            </div>
                            <div className="text-muted small mt-1">5h ago</div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="dropdown-menu-footer">
                      <a href="#" className="text-muted">
                        Show all messages
                      </a>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <i className="align-middle" data-feather="settings"></i>
                  </a>

                  <a
                    className="nav-link dropdown-toggle d-none d-sm-inline-block"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6ZM17.8946 17.4473L17.0002 17C17.8946 17.4473 17.8939 17.4487 17.8939 17.4487L17.8932 17.4502L17.8916 17.4532L17.8882 17.46L17.88 17.4756C17.8739 17.4871 17.8666 17.5004 17.858 17.5155C17.8409 17.5458 17.8186 17.5832 17.7906 17.6267C17.7346 17.7138 17.6558 17.8254 17.5497 17.9527C17.3369 18.208 17.0163 18.5245 16.5549 18.8321C15.6228 19.4534 14.1751 20 12.0001 20C8.31494 20 6.76549 18.4304 6.26653 17.7115C5.96463 17.2765 5.99806 16.7683 6.18066 16.4031C6.91705 14.9303 8.42234 14 10.069 14H13.7642C15.5134 14 17.1124 14.9883 17.8947 16.5528C18.0354 16.8343 18.0354 17.1657 17.8946 17.4473Z"
                        fill="#152C70"
                      />
                    </svg>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a className="dropdown-item" href="pages-profile.html">
                      <i className="align-middle me-1" data-feather="user"></i>{" "}
                      Profile
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="index.html">
                      <i
                        className="align-middle me-1"
                        data-feather="settings"
                      ></i>{" "}
                      Settings & Privacy
                    </a>
                    <a className="dropdown-item" href="#">
                      <i
                        className="align-middle me-1"
                        data-feather="help-circle"
                      ></i>{" "}
                      Help Center
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#">
                      Log out
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>

          <main className="content" style={{ padding: 5, overflow: "auto" }}>
            <div className="container-fluid p-0">{pages()}</div>
          </main>

          <footer className="footer">
            <div className="container-fluid">
              <div className="row text-muted">
                <div className="col-6 text-start">
                  <p className="mb-0">
                    <a
                      className="text-muted"
                      href="https://WaEPOS.com/"
                      target="_blank"
                    >
                      <strong>WaEPOS</strong>
                    </a>{" "}
                    &copy;
                  </p>
                </div>
                <div className="col-6 text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a
                        className="text-muted"
                        href="https://WaEPOS.com/"
                        target="_blank"
                      >
                        Support
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="text-muted"
                        href="https://WaEPOS.com/"
                        target="_blank"
                      >
                        Help Center
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="text-muted"
                        href="https://WaEPOS.com/"
                        target="_blank"
                      >
                        Privacy
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="text-muted"
                        href="https://WaEPOS.com/"
                        target="_blank"
                      >
                        Terms
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );

  const pages = (): JSX.Element => (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Products" element={<AllProducts />} />
        <Route path="/AddProduct" element={<AddProduct />} />
        <Route path="/AddProduct/:id" element={<AddProduct />} />
        <Route path="/AddProduct/:id/:type/:nav" element={<AddProduct />} />

        <Route path="/Instructions" element={<Instructions />} />
        <Route path="/AddInstruction" element={<AddInstructions />} />
        <Route path="/MultiInstruction" element={<MultiInstructions />} />
        <Route path="/AddInstruction/:id" element={<AddInstructions />} />
        <Route
          path="/AddInstruction/:id/:type/:nav"
          element={<AddInstructions />}
        />
        <Route path="/CopyInstructions" element={<CopyInstructions />} />
        <Route
          path="/CopyInstructions/:id/:nav"
          element={<CopyInstructions />}
        />
        <Route
          path="/SelectInstructions/:id/:nav"
          element={<SelectInstructions />}
        />

        <Route path="/Options" element={<Options />} />
        <Route path="/AddOption" element={<AddOption />} />
        <Route path="/AddOption/:id" element={<AddOption />} />

        <Route path="/Extras" element={<Extras />} />
        <Route path="/AddExtra" element={<AddExtra />} />
        <Route path="/AddExtra/:id" element={<AddExtra />} />

        <Route path="/Variations" element={<Variations />} />
        <Route path="/AddVariation" element={<AddVariation />} />
        <Route path="/AddVariation/:id" element={<AddVariation />} />

        <Route path="/Calls" element={<Calls />} />

        <Route path="/TreeView" element={<ProductTree />} />
      </Routes>
    </>
  );

  const manager = (): JSX.Element => (
    <>
      {loginView ? (
        <Login
          successful={handleLoginSuccess}
          register={handleRegister}
          forgotPassword={handleForgotPassword}
        />
      ) : (
        <></>
      )}
      {loginView === false ? <>{template()}</> : <></>}
    </>
  );

  const loadingData = (): JSX.Element => {
    return (
      <>
        {loadingError ? (
          <div className="loading-center">
            {/* <img src={loadingImage} alt="Loading" /> */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Error in communication with service
          </div>
        ) : (
          <div className="loading-center">
            <img src={loadingImage} alt="Loading" />
          </div>
        )}
      </>
    );
  };

  return <div>{loading ? loadingData() : <>{manager()}</>}</div>;
};
