import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import {
  getReceipts,
  getSettings,
  storeProduct,
} from "../../../services/Service";
import { IProduct } from "../../../types/IProduct";
import { ISearchData } from "../../../types/ISearchData";
import ApplicationContext from "../../../context/ApplicationContext";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { IReceipt } from "../../../types/IReceipt";
import loadingImage from "../../../images/loading.gif";
import { IAdmin } from "../../../types/IAdmin";
import { ITelephone } from "../../../types/ITelephone";

export const Calls: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const navigate = useNavigate();

  var today = new Date();

  var defaultAdmin: IAdmin = {
    receipts: [],
    telephones: [],
  };

  const [adminData, setAdminData] = useState(defaultAdmin);
  const [receipts, setReceipts] = useState<IReceipt[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 1)
  );

  async function getReceiptData() {
    setLoading(true);
    const response = await getReceipts(
      startDate,
      endDate,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    setAdminData(response);

    if (response.receipts !== undefined) {
      setReceipts(response.receipts);
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setLoading(false);
  }

  function callHistory() {
    navigate("/Calls");
  }

  useEffect(() => {
    getReceiptData();
  }, []);

  function getTotal() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      total = total + element.total;
    }

    return total;
  }

  function paidByCard() {
    let card = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.paymentType === "card") {
        card = card + 1;
      }
    }
    return card;
  }

  function totalCardPayment() {
    let cardTotal = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.paymentType === "Card") {
        cardTotal = cardTotal + element.total;
      }
    }
    return cardTotal;
  }

  function totalCashPayment() {
    let cashTotal = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.paymentType === "Cash") {
        cashTotal = cashTotal + element.total;
      }
    }
    return cashTotal;
  }

  function eatInCount() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.transactionType === "Eat in") {
        total = total + 1;
      }
    }
    return total;
  }

  function collectionCount() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.transactionType === "Collection") {
        total = total + 1;
      }
    }
    return total;
  }

  function takeOverCount() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.transactionType === "Takeout") {
        total = total + 1;
      }
    }
    return total;
  }

  function totalCalls() {
    let total = adminData.telephones.length;
    return total;
  }

  function formatDate(d: Date) {
    var date = d.toISOString().substring(0, 10);
    return date;
  }

  function formatTime(d: Date) {
    return d.toLocaleString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  function onStartChangeDate(value: string) {
    let dt = new Date(value);

    dt.setHours(startDate.getHours());
    dt.setMinutes(startDate.getMinutes());

    setStartDate(dt);
  }

  function onStartChangeTime(value: string) {
    const timeParts = value.split(":");

    let dt = new Date(startDate);
    dt.setHours(Number(timeParts[0]));
    dt.setMinutes(Number(timeParts[1]));

    setStartDate(dt);
  }

  function onEndChangeDate(value: string) {
    let dt = new Date(value);

    dt.setHours(endDate.getHours());
    dt.setMinutes(endDate.getMinutes());

    setEndDate(dt);
  }

  function onEndChangeTime(value: string) {
    const timeParts = value.split(":");

    let dt = new Date(endDate);
    dt.setHours(Number(timeParts[0]));
    dt.setMinutes(Number(timeParts[1]));

    setEndDate(dt);
  }

  function applyFilter() {
    getReceiptData();
  }

  const calls = (): JSX.Element => (
    <>
      <div className="container-fluid">
        <h3>Calls</h3>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Created At</th>
              <th scope="col">Contact</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>{adminData.telephones.map((data) => callsRow(data))}</tbody>
        </table>
      </div>
    </>
  );

  function formatDateForDisplay(d: Date) {
    return (
      d.getDate() +
      "/" +
      d.getMonth() +
      "/" +
      d.getFullYear() +
      " - " +
      d.getHours() +
      ":" +
      d.getMinutes()
    );
  }

  const callsRow = (r: ITelephone) => (
    <>
      <tr>
        <td>{formatDateForDisplay(new Date(r.createdAt))}</td>
        <td>
          {r.contact}
          {r.customer ? r.customer.name : ""}
        </td>
        <td>
          <Button variant="link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-copy"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
              />
            </svg>
          </Button>
        </td>
      </tr>
    </>
  );
  return (
    <>
      <>{calls()}</>
    </>
  );
};
