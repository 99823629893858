import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Dropdown,
  DropdownItemProps,
} from "semantic-ui-react";
import {
  deleteReceipt,
  getReceipts,
  getSettings,
  storeProduct,
} from "../../../services/Service";
import { IProduct } from "../../../types/IProduct";
import { ISearchData } from "../../../types/ISearchData";
import ApplicationContext from "../../../context/ApplicationContext";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GenerateGUID } from "../../../helpers/Helpers";
import { IReceipt } from "../../../types/IReceipt";
import loadingImage from "../../../images/loading.gif";
import { IAdmin } from "../../../types/IAdmin";

export const Dashboard: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const navigate = useNavigate();

  var today = new Date();

  var defaultAdmin: IAdmin = {
    receipts: [],
    telephones: [],
  };

  const [adminData, setAdminData] = useState(defaultAdmin);
  const [receipts, setReceipts] = useState<IReceipt[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 1)
  );

  const [showFilter, setShowFilter] = useState(false);

  async function getReceiptData() {
    setLoading(true);
    const response = await getReceipts(
      startDate,
      endDate,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    setAdminData(response);

    if (response.receipts !== undefined) {
      setReceipts(response.receipts);
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setLoading(false);
  }

  function callHistory() {
    navigate("/Calls");
  }

  useEffect(() => {
    getReceiptData();
  }, []);

  function getTotal() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      total = total + element.total;
    }

    return total;
  }

  function paidByCard() {
    let card = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.paymentType === "card") {
        card = card + 1;
      }
    }
    return card;
  }

  function totalCardPayment() {
    let cardTotal = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.paymentType === "Card") {
        cardTotal = cardTotal + element.total;
      }
    }
    return cardTotal;
  }

  function totalCashPayment() {
    let cashTotal = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.paymentType === "Cash") {
        cashTotal = cashTotal + element.total;
      }
    }
    return cashTotal;
  }

  function eatInCount() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.transactionType === "Eat in") {
        total = total + 1;
      }
    }
    return total;
  }

  function collectionCount() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.transactionType === "Collection") {
        total = total + 1;
      }
    }
    return total;
  }

  function takeOverCount() {
    let total = 0;
    for (let idx = 0; idx < receipts.length; idx++) {
      const element = receipts[idx];
      if (element.transactionType === "Takeout") {
        total = total + 1;
      }
    }
    return total;
  }

  function totalCalls() {
    let total = adminData.telephones.length;
    return total;
  }

  function formatDate(d: Date) {
    var date = d.toISOString().substring(0, 10);
    return date;
  }

  function formatTime(d: Date) {
    return d.toLocaleString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  function onStartChangeDate(value: string) {
    let dt = new Date(value);

    dt.setHours(startDate.getHours());
    dt.setMinutes(startDate.getMinutes());

    setStartDate(dt);
  }

  function onStartChangeTime(value: string) {
    const timeParts = value.split(":");

    let dt = new Date(startDate);
    dt.setHours(Number(timeParts[0]));
    dt.setMinutes(Number(timeParts[1]));

    setStartDate(dt);
  }

  function onEndChangeDate(value: string) {
    let dt = new Date(value);

    dt.setHours(endDate.getHours());
    dt.setMinutes(endDate.getMinutes());

    setEndDate(dt);
  }

  function onEndChangeTime(value: string) {
    const timeParts = value.split(":");

    let dt = new Date(endDate);
    dt.setHours(Number(timeParts[0]));
    dt.setMinutes(Number(timeParts[1]));

    setEndDate(dt);
  }

  function applyFilter() {
    getReceiptData();
  }

  const dashboard = (): JSX.Element => (
    <>
      <div className="container-fluid">
        <div className="mb-3">
          <h1>Dashboard</h1>
        </div>

        <Accordion fluid styled>
          <AccordionTitle
            active={showFilter}
            onClick={() => setShowFilter(!showFilter)}
          >
            Filter
          </AccordionTitle>
          <AccordionContent active={showFilter}>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Report range</h5>
                <br />
                <p className="card-text">
                  Start date time
                  <br />
                  <input
                    type="date"
                    style={{ fontSize: 16 }}
                    value={formatDate(startDate)}
                    onChange={(e) => onStartChangeDate(e.target.value)}
                  ></input>
                  <input
                    type="time"
                    id="appt"
                    style={{ fontSize: 16 }}
                    value={formatTime(startDate)}
                    onChange={(e) => onStartChangeTime(e.target.value)}
                    name="appt"
                  ></input>
                  <br />
                  <br />
                  End date time <br />
                  <input
                    type="date"
                    style={{ fontSize: 16 }}
                    value={formatDate(endDate)}
                    onChange={(e) => onEndChangeDate(e.target.value)}
                  ></input>
                  <input
                    type="time"
                    style={{ fontSize: 16 }}
                    value={formatTime(endDate)}
                    onChange={(e) => onEndChangeTime(e.target.value)}
                  ></input>
                </p>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={applyFilter}
                >
                  Apply
                </button>
              </div>
            </div>
          </AccordionContent>
        </Accordion>

        {/* <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Filter
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Report range</h5>
                    <br />
                    <p className="card-text">
                      Start date time
                      <br />
                      <input
                        type="date"
                        style={{ fontSize: 16 }}
                        value={formatDate(startDate)}
                        onChange={(e) => onStartChangeDate(e.target.value)}
                      ></input>
                      <input
                        type="time"
                        id="appt"
                        style={{ fontSize: 16 }}
                        value={formatTime(startDate)}
                        onChange={(e) => onStartChangeTime(e.target.value)}
                        name="appt"
                      ></input>
                      <br />
                      <br />
                      End date time <br />
                      <input
                        type="date"
                        style={{ fontSize: 16 }}
                        value={formatDate(endDate)}
                        onChange={(e) => onEndChangeDate(e.target.value)}
                      ></input>
                      <input
                        type="time"
                        style={{ fontSize: 16 }}
                        value={formatTime(endDate)}
                        onChange={(e) => onEndChangeTime(e.target.value)}
                      ></input>
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={applyFilter}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <hr />

        <div className="row">
          <div className="col-12 col-md-3 d-flex">
            <div className="card flex-fill border-0 illustration">
              <div className="card-body p-0 d-flex flex-fill">
                <div className="row g-0 w-100">
                  <div className="col-12 w-100">
                    <div className="p-3 m-1">
                      <h2 className="text-success">
                        {loading ? (
                          <img
                            src={loadingImage}
                            alt="Loading"
                            width={200}
                            height={40}
                          />
                        ) : (
                          "£" + getTotal().toFixed(2)
                        )}
                      </h2>
                      <p className="mb-0">
                        Card: £{totalCardPayment().toFixed(2)}
                      </p>
                      <p className="mb-0">
                        Cash: £{totalCashPayment().toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 d-flex">
            <div className="card flex-fill border-0">
              <div className="card-body py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h2 className="text-success">
                      {loading ? (
                        <img
                          src={loadingImage}
                          alt="Loading"
                          width={200}
                          height={40}
                        />
                      ) : (
                        receipts.length + " Orders"
                      )}
                    </h2>
                    <p className="mb-0">Eat in: {eatInCount()}</p>
                    <p className="mb-0">Takeout: {takeOverCount()}</p>
                    <p className="mb-0">Collection: {collectionCount()}</p>
                    <p className="mb-0">Delivery: {paidByCard()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 d-flex">
            <div className="card flex-fill border-0">
              <div className="card-body py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h2 className="text-success">
                      {loading ? (
                        <img
                          src={loadingImage}
                          alt="Loading"
                          width={200}
                          height={40}
                        />
                      ) : (
                        totalCalls() + " Calls"
                      )}
                    </h2>
                    <p className="mb-2">Numbers registered</p>
                    <div className="mb-0">
                      <span className="badge text-success me-2">+100%</span>
                      <span className="text-muted">Since Last Month</span>
                      <p className="mb-0">
                        <Button variant="link" onClick={() => callHistory()}>
                          Call history
                        </Button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3 d-flex">
            <div className="card flex-fill border-0">
              <div className="card-body py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h2 className="text-success">
                      {loading ? (
                        <img
                          src={loadingImage}
                          alt="Loading"
                          width={200}
                          height={40}
                        />
                      ) : (
                        totalCalls() + "  App Orders"
                      )}
                    </h2>
                    <p className="mb-2">App orders</p>
                    <div className="mb-0">
                      <span className="badge text-success me-2">+100%</span>
                      <span className="text-muted">Since Last Month</span>
                      <p className="mb-0">
                        <Button variant="link" onClick={() => callHistory()}>
                          Order history
                        </Button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card border-0">
          <div className="card-header">
            <h5 className="card-title">Receipts</h5>
            <h6 className="card-subtitle text-muted"></h6>
          </div>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Total</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>{receipts.map((data) => receiptRow(data))}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

  function formatDateForDisplay(d: Date) {
    return (
      d.getDate() +
      "/" +
      d.getMonth() +
      "/" +
      d.getFullYear() +
      " - " +
      d.getHours() +
      ":" +
      d.getMinutes()
    );
  }

  async function removeReceipt(r: IReceipt) {
    setLoading(true);

    const response = await deleteReceipt(
      r.id,
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    getReceiptData();

    setLoading(false);
  }

  function generateHash() {
    let x = Math.floor(Math.random() * 999999999 + 1);
    return x.toString();
  }

  const receiptRow = (r: IReceipt) => (
    <>
      <tr>
        <td>{generateHash()}</td>
        <td>{formatDateForDisplay(new Date(r.createdAt))}</td>
        <td>{r.total.toFixed(2)}</td>
        <td>
          <Button variant="link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-copy"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
              />
            </svg>
          </Button>
        </td>
        <td>
          <Button variant="link" onClick={() => removeReceipt(r)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </Button>
        </td>
      </tr>
    </>
  );
  return (
    <>
      <>{dashboard()}</>
    </>
  );
};
